<template>
  <div>
    <card shadow hover header-classes="bg-transparent">
      <div slot="header" class="row align-items-center">
        <div class="col">
          <h2 class="h2 ls-1 mb-0">
            <router-link :to="{ name: 'post', params: {alias: post.alias, title: post.title} }">
              {{ post.title }}
            </router-link>
          </h2>
        </div>
      </div>
      <div slot="footer">
        <div class="row">
          <div class="col-6 col-xl-3 col-lg-6">
            <h5 class="mb-1"><strong>发布时间：</strong>{{ post.c_t | formatDate }}</h5>
          </div>
          <div class="col-6 col-xl-3 col-lg-6">
            <h5 class="mb-1"><strong>作者：</strong><a href="#">{{ post.username }}</a></h5>
          </div>
          <div class="col-6 col-xl-3 col-lg-6">
            <h5 class="mb-1"><strong>分类：</strong>
              <badge type="primary">{{ post.cate_name }}</badge>
            </h5>
          </div>
          <div class="col-6 col-xl-3 col-lg-6">
            <h5 class="mb-1"><strong>阅读数：</strong>{{ post.views }}</h5>
          </div>
        </div>
      </div>
      <p class="mb-0">
        <lone-mark :content="post.text + '...'"></lone-mark>
      </p>
      <router-link :to="{ name: 'post', params: {alias: post.alias, title: post.title} }">
        阅读全部 →
      </router-link>
    </card>
    <br/>
  </div>
</template>

<script>
import {formatDate} from "../../utils";

export default {
  name: "post-card",
  props: {
    post: Object
  },
  filters: {
    formatDate
  }
};
</script>

<style>
.post-header {
  color: #5e72e4;
  font-size: 1.625rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.post-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.75rem 1.5rem;
}

.post-detail-icon {
  font-size: medium;
  margin-right: 0.5rem;
}

.post-detail-icon-text {
  margin-top: 10rem;
}


p code, h1 code, h2 code, h3 code, h4 code, h5 code {
  font-size: inherit;
  font-weight: 600;
  color: #32325d;
  word-wrap: break-word;
}

.hljs {
  color: #24292e;
  background-color: #FFF;
  border: 1px solid #ccc;
  border-radius: 5px;
  word-break: break-all;
  word-wrap: break-word;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}


.badge {
  display: inline-block;
  padding: 0.2rem 0.275rem;
  font-size: 66%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

</style>
