import { render, staticRenderFns } from "./BlogIndex.vue?vue&type=template&id=5312835c&scoped=true&"
import script from "./BlogIndex.vue?vue&type=script&lang=js&"
export * from "./BlogIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5312835c",
  null
  
)

export default component.exports