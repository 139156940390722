<template>
  <div>
    <base-header type="gradient-success" class="pb-8 pt-5">
    </base-header>


    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">分类管理</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button size="sm" type="info" @click="getAllCategory">刷新数据</base-button>
                  <base-button size="sm" type="success" @click="showModal(0, null)">新增分类</base-button>
                </div>
              </div>
            </div>
            <template>
              <div class="table-responsive">
                <base-table thead-classes="thead-light"
                            :data="queryResult.list">
                  <template slot="columns">
                    <th> #</th>
                    <th>名称</th>
                    <th>文章数</th>
                    <th>状态</th>
                    <th>创建时间</th>
                    <th>操作</th>
                  </template>

                  <template slot-scope="{row}">
                    <td>
                      <h5>{{ row.index }}</h5>
                    </td>
                    <td>
                      <h5>{{ row.name }}</h5>
                    </td>
                    <td>
                      {{ row.post_count }}
                    </td>
                    <td>
                      <badge v-if="row.is_show==0" size="sm" type="warning">隐藏</badge>
                      <badge v-if="row.is_show==1" size="sm" type="success">显示</badge>
                    </td>
                    <td>
                      {{ row.c_t | formatTime }}
                    </td>
                    <th scope="row">
                      <base-button size="sm" rounded type="primary" @click="moveCategory(row.id, 1)">
                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                      </base-button>
                      <base-button size="sm" rounded type="success" @click="moveCategory(row.id, 2)">
                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                      </base-button>
                      <base-button size="sm" type="info" @click="showModal(1, row)">编辑</base-button>
                      <base-button v-if="row.is_show==1" size="sm" type="warning" @click="toggleStatus(row.id, 0)">隐藏</base-button>
                      <base-button v-if="row.is_show==0" size="sm" type="success" @click="toggleStatus(row.id, 1)">显示</base-button>
                      <base-button size="sm" type="danger" @click="onConfirmDel(row)">删除</base-button>
                    </th>
                  </template>

                </base-table>
              </div>
            </template>
            <modal :show.sync="items.confirmModalShow" gradient="danger" modal-classes="modal-danger modal-dialog-centered">
              <h6 slot="header" class="modal-title" id="modal-title-notification">删除分类</h6>
              <div class="py-3 text-center">
                  <i class="ni ni-bell-55 ni-3x"></i>
                  <h4 class="heading mt-4">确认删除分类【{{delRow.name}}】?</h4>
                  <p>分类删除后重建可恢复。</p>
              </div>
              <template slot="footer">
                  <base-button type="white" @click="onDel">删除</base-button>
                  <base-button type="link" text-color="white" class="ml-auto" @click="abortDel">取消</base-button>
              </template>
            </modal>
            <modal :show.sync="items.addModalShow">
              <template slot="header">
                <h5 class="modal-title">{{ items.modalTitle }}</h5>
              </template>
              <div>
                <template>
                  <form role="form">
                    <base-input alternative="" label="分类名称">
                      <input v-model="category.name" ref="title" class="form-control form-control-alternative"
                             placeholder="分类名称">
                    </base-input>
                    <base-input alternative="" label="分类描述">
                      <input v-model="category.description" ref="title" class="form-control form-control-alternative"
                             placeholder="分类描述">
                    </base-input>
                    <base-input alternative="" label="序号">
                      <input v-model.number="category.index" ref="title" class="form-control form-control-alternative"
                             placeholder="序号">
                    </base-input>
                    <base-input alternative="" label="是否可见">
                      <base-switch before="隐藏" after="显示" :value="category.is_show" @click="isShow"></base-switch>
                    </base-input>
                  </form>
                </template>
              </div>
              <template slot="footer">
                <base-button type="secondary" size="sm" @click="items.addModalShow = false">取消</base-button>
                <base-button type="primary" size="sm" @click="addOrEdit">保存</base-button>
              </template>
            </modal>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import {getCategoryListWithPostCount, addCategory, editCategory, moveCategory, categoryShowToggle, delCategory} from "../../api";
import {Notify, formatTime} from "../../utils";

export default {
  data() {
    return {
      items: {
        addModalShow: false,
        confirmModalShow: false,
        modalTitle: "",
        modalType: 0
      },
      category: {
        id: null,
        name: null,
        description: null,
        index: null,
        is_show: 1
      },
      delRow: {},
      queryResult: {
        total: null,
        list: []
      }
    }
  },
  created() {
    this.getAllCategory();
  },
  watch: {},
  filters: {
    formatTime,
    showFilter(val) {
      switch (val) {
        case 0:
          return "隐藏";
        case 1:
          return "显示";
        default:
          return "";
      }
    }
  },
  methods: {
    getAllCategory() {
      getCategoryListWithPostCount().then(res => {
        if (res.data.code === 0) {
          this.queryResult.list = res.data.data.items;
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    addOrEdit() {
      this.category.id == null ? this.addNewCategory() : this.editExistCategory();
    },
    addNewCategory() {
      addCategory(this.category).then(res => {
        if (res.data.code === 0) {
          this.items.addModalShow = false;
          this.getAllCategory();
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    editExistCategory() {
      editCategory(this.category).then(res => {
        if (res.data.code === 0) {
          this.items.addModalShow = false;
          this.getAllCategory();
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    clearCategory() {
      this.category.id = null;
      this.category.name = null;
      this.category.description = null;
      this.category.index = null;
      this.category.is_show = 1;
    },
    initCategoryOnEdit(category) {
      this.category.id = category.id;
      this.category.name = category.name;
      this.category.description = category.description;
      this.category.index = category.index;
      this.category.is_show = category.is_show;
    },
    moveCategory(id, type) {
      // type 1-->Up 2-->Down
      moveCategory({id: id, type: type})
      .then(res => {
        if (res.data.code === 0) {
          this.getAllCategory();
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    toggleStatus(id, is_show) {
      categoryShowToggle(id, is_show)
      .then(res => {
        if (res.data.code === 0) {
          this.getAllCategory();
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    showModal(type, row) {
      this.items.addModalShow = true;
      switch (type) {
        case 0:
          this.clearCategory();
          this.items.modalTitle = "新增分类";
          this.items.modalType = 0;
          break;
        case 1:
          this.items.modalTitle = "编辑分类";
          this.initCategoryOnEdit(row);
          this.items.modalType = 1;
          break;
        default:
          Notify.error("wrong type of modal!");
      }
    },
    isShow() {
      this.category.is_show = this.category.is_show === 1 ? 0 : 1;
    },
    onConfirmDel(row) {
      this.delRow = row;
      this.items.confirmModalShow = true;
    },
    abortDel() {
      this.delRow = {};
      this.items.confirmModalShow = false;
    },
    onDel() {
      let id = this.delRow.id;
      delCategory(id).then(res => {
        if (res.data.code === 0) {
          this.items.confirmModalShow = false;
          this.getAllCategory();
          Notify.success(res.data.msg);
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    }
  }
};
</script>
<style scoped>

/deep/ .form-control-label {
  width: 80px;
  margin-bottom: -20px;
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.form-control {
  font-size: 0.5rem;
  display: inline;
  width: 320px;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.2;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
}

</style>
