<template>
  <div>
    <post-card v-for="(post, index) in result.posts" :key="index" :post="post"></post-card>
<!--    loading...-->
<!--    <base-pagination v-show="result.total > 0" align="end" v-model="param.page_number" :value="param.page_number"-->
<!--                     :total="result.total" :perPage="param.page_size" size="sm"></base-pagination>-->
  </div>
</template>

<script>
import PostCard from "@/views/Blog/PostCard";

import {getAllCategory, getPosts} from "../../api";
import {Notify, isEmpty} from "../../utils";

export default {
  name: "blog-index",
  data() {
    return {
      cateList: [],
      param: {
        cate_id: null,
        type: 0,
        page_number: 0,
        page_size: 8
      },
      result: {
        init: false,
        total: 0,
        posts: []
      }
    };
  },
  beforeMount() {
    this.initPosts(this.$route.name, this.$route.params.name, true);
  },
  mounted() {
    this.getNextPosts();
  },
  created() {
    this.initCategoryList();
  },
  watch: {
    $route() {
      this.param.page_number = 0;
      this.result.total = 0;
      this.result.posts = [];
      this.initPosts(this.$route.name, this.$route.params.name, false);
    }
  },
  methods: {
    initCategoryList() {
      getAllCategory().then(res => {
        if (res.data.code === 0) {
          this.cateList = res.data.data;
        } else {
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    initPosts(routeName, cateName, checkCateList) {
      if (routeName === 'index' && cateName === undefined) {
        this.param.cate_id = null;
        this.getPosts();
      }
      if (routeName === 'category' && cateName !== undefined) {
        if (checkCateList) {
          getAllCategory().then(res => {
            if (res.data.code === 0) {
              this.cateList = res.data.data;
            } else {
              Notify.error(res.data.code + ": " + res.data.msg);
            }
          }).then(() => {
            this.matchCategoryAndGetPost(cateName);
          });
        } else {
          this.matchCategoryAndGetPost(cateName);
        }
      }
    },
    matchCategoryAndGetPost(cateName) {
      let category = this.cateList.find(item => item.name === cateName);
      if (!isEmpty(category)) {
        this.param.cate_id = category.id;
        this.getPosts();
      }
    },
    getPosts() {
      this.param.page_number++;
      getPosts(this.param).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.items.length > 0) {
            this.result.total = res.data.data.total;
            this.result.posts.push(...res.data.data.items);
          } else {
            this.param.page_number--;
            Notify.warn("没有更多文章啦~");
          }
        } else {
          this.param.page_number--;
          Notify.error(res.data.code + ": " + res.data.msg);
        }
      })
    },
    getNextPosts() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (this.$route.name === 'index' && bottomOfWindow) {
          this.getPosts();
        }
      };
    }
  },
  components: {
    PostCard
  }
};
</script>

<style scoped>

</style>